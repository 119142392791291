import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';
import { finalize } from 'rxjs';

let requestCount = 0;

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const skip =
    'https://global-ship-bmac-macae-api-develop.mgr.services/api/v1/NotificacaoUsuario';

  const loaderService = inject(LoaderService);

  const skipUrl = req.url.includes(skip);

  if (!skipUrl) {
    ++requestCount;
    loaderService.abrir();
  }

  return next(req).pipe(
    finalize(() => {
      if (!skipUrl) {
        --requestCount;
        if (requestCount === 0) {
          loaderService.fechar();
        }
      }
    })
  );
};
